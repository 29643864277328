@import '../../../general/scss/settings/variables.scss';
@import '../../../general/scss/settings/mixins.scss';

.page-link {
    color: $unipol-green;
}

.page-item {
    &.active {
        .page-link {
            background-color: $unipol-green;
            border-color: $unipol-green;
        }
    }
}

.pagination {
    ul {
        border-radius: 0;
        box-shadow: none;
        padding-left: 0;

        & > li:first-child > a,
        & > li:first-child > span {
            border-radius: 0 !important;
        }

        li {
            display: inline-block;

            a {
                position: relative;
                display: block;
                cursor: pointer;
                padding: 0.5rem 0.75rem;
                margin-left: -1px;
                line-height: 1.25;
                color: $unipol-green;
                background-color: $white;
                border: 1px solid $unipol-off-white;
                transition: background-color 0.3s;

                &:hover {
                    transition: background-color 0.3s;
                    z-index: 2;
                    color: $unipol-dark-blue;
                    text-decoration: none;
                    background-color: #e9ecef;
                    border-color: $unipol-off-white;
                }

                &:focus {
                    z-index: 3;
                    outline: 0;
                    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
                }
            }

            span {
                position: relative;
                display: block;
                padding: 0.5rem 0.75rem;
                margin-left: -1px;
                line-height: 1.25;
                color: $black;
                background-color: $white;
                border: 1px solid $unipol-off-white;
                transition: background-color 0.3s;

                &:hover {
                    transition: background-color 0.3s;
                    z-index: 2;
                    color: $unipol-dark-blue;
                    text-decoration: none;
                    background-color: #e9ecef;
                    border-color: $unipol-off-white;
                }

                &:focus {
                    z-index: 3;
                    outline: 0;
                    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
                }
            }

            &.disabled{
                opacity: .7;
                pointer-events: all !important;
                a{
                    cursor: not-allowed;
                }
            }
        }
    }
}

